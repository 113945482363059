import './App.css';
import Root from './views/Root';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import Gdansk from './views/Gdansk';
import Duchnice from './views/Duchnice';
import Utrecht from './views/Utrecht';
import Amsterdam from './views/Amsterdam';

function App() {
  if(isMobile){
    return(
      <div className='onlyOnMobile'>Ta strona jest dostępna na komputerach<br />This page is available on desktop</div>
    )
  }
  return (
    <BrowserRouter>
      <div className="App">
          <Switch>
            <Route exact path="/" component={Root} />
            <Route path="/Gdansk" component={Gdansk} />
            <Route path="/Duchnice" component={Duchnice} />
            <Route path="/Utrecht" component={Utrecht} />
            <Route path="/Amsterdam" component={Amsterdam} />
          </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
